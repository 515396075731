import { ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Slider } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { db, functions } from "../firebase";
import { clapperState, windowSize } from "../recoil/recoil";
import { ClapperData } from "../typeDefs";
import { httpsCallable } from "firebase/functions";

export const ClapperBoard = () => {
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [titleEdit, setTitleEdit] = useState(false);
  const [sceneEdit, setSceneEdit] = useState(false);
  const [takeEdit, setTakeEdit] = useState(false);
  const clapper = useRecoilValue(clapperState);
  const { height } = useRecoilValue(windowSize);
  const [titleForm] = Form.useForm();
  const [sceneForm] = Form.useForm();
  const [takeForm] = Form.useForm();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().getTime() - clapper.startTime);
    }, 100);

    return () => clearInterval(timer);
  }, [clapper]);

  useEffect(() => {
    const helloWorld = httpsCallable(functions, "helloWorld");
    helloWorld("Message").then((res: any) => {
      console.log(res);
    });
  }, []);

  const saveClapperData = (data: ClapperData) => {
    updateDoc(doc(db, "clapper/4ofv60qFSlgMrJZXFV8h"), data);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,.97)",
        zIndex: "30",
        //height: "100vh",
      }}
    >
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          alignItems: "center",
          fontSize: `${clapper.scale / 3}rem`,
          padding: "5rem",
        }}
      >
        <div
          onDoubleClick={() =>
            titleEdit
              ? () => {
                  titleForm
                    .validateFields()
                    .then((values) => {
                      saveClapperData({ ...clapper, title: values.title });
                      setTitleEdit(false);
                    })
                    .catch((err) => console.error(err));
                }
              : setTitleEdit(true)
          }
        >
          {titleEdit ? (
            <Form form={titleForm} layout="inline">
              <Form.Item name="title">
                <Input
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      titleForm
                        .validateFields()
                        .then((values) => {
                          saveClapperData({ ...clapper, title: values.title });
                          setTitleEdit(false);
                        })
                        .catch((err) => console.error(err));
                    }
                  }}
                />
              </Form.Item>
              <Button
                icon={<SaveOutlined />}
                onClick={() =>
                  titleForm
                    .validateFields()
                    .then((values) => {
                      saveClapperData({ ...clapper, title: values.title });
                      setTitleEdit(false);
                    })
                    .catch((err) => console.error(err))
                }
              />
            </Form>
          ) : (
            `PROJECT: ${clapper.title.toUpperCase()}`
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ paddingRight: `${clapper.scale / 10}rem` }}
            onDoubleClick={() =>
              sceneEdit
                ? () => {
                    sceneForm
                      .validateFields()
                      .then((values) => {
                        // setClapper((prev) => {
                        //   return { ...prev, scene: values.title };
                        // });
                        saveClapperData({ ...clapper, scene: values.scene });
                        setSceneEdit(false);
                      })
                      .catch((err) => console.error(err));
                  }
                : setSceneEdit(true)
            }
          >
            {sceneEdit ? (
              <Form form={sceneForm} layout="inline">
                <Form.Item name="scene">
                  <InputNumber
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        sceneForm
                          .validateFields()
                          .then((values) => {
                            saveClapperData({
                              ...clapper,
                              scene: values.scene,
                            });
                            setSceneEdit(false);
                          })
                          .catch((err) => console.error(err));
                      }
                    }}
                  />
                </Form.Item>
                <Button
                  icon={<SaveOutlined />}
                  onClick={() =>
                    sceneForm
                      .validateFields()
                      .then((values) => {
                        saveClapperData({ ...clapper, scene: values.scene });
                        setSceneEdit(false);
                      })
                      .catch((err) => console.error(err))
                  }
                />
              </Form>
            ) : (
              `SCENE: ${clapper.scene}`
            )}
          </div>
          <div
            onDoubleClick={() =>
              takeEdit
                ? () => {
                    takeForm
                      .validateFields()
                      .then((values) => {
                        saveClapperData({ ...clapper, take: values.take });
                        setTakeEdit(false);
                      })
                      .catch((err) => console.error(err));
                  }
                : setTakeEdit(true)
            }
          >
            {takeEdit ? (
              <Form form={takeForm} layout="inline">
                <Form.Item name="take">
                  <InputNumber
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        takeForm
                          .validateFields()
                          .then((values) => {
                            saveClapperData({ ...clapper, take: values.take });
                            setTakeEdit(false);
                          })
                          .catch((err) => console.error(err));
                      }
                    }}
                  />
                </Form.Item>
                <Button
                  icon={<SaveOutlined />}
                  onClick={() =>
                    takeForm
                      .validateFields()
                      .then((values) => {
                        saveClapperData({ ...clapper, take: values.take });
                        setTakeEdit(false);
                      })
                      .catch((err) => console.error(err))
                  }
                />
              </Form>
            ) : (
              `TAKE: ${clapper.take}`
            )}
          </div>
        </div>
        <div>{new Date().toDateString()}</div>
        <Slider
          value={clapper.scale}
          onChange={(val) => saveClapperData({ ...clapper, scale: val })}
          min={0.1}
          max={15}
          style={{ width: "50vw" }}
        />
        {currentTime && (
          <div
            style={{
              color: "rgb(255, 30, 30)",
              fontSize: `${clapper.scale}rem`,
            }}
            onDoubleClick={() => {
              saveClapperData({ ...clapper, startTime: new Date().getTime() });
            }}
          >{`${
            new Date(currentTime).getHours() - 12 < 10
              ? `0${new Date(currentTime).getHours() - 12}`
              : `${new Date(currentTime).getHours() - 12}`
          }:${
            new Date(currentTime).getMinutes() < 10
              ? `0${new Date(currentTime).getMinutes()}`
              : `${new Date(currentTime).getMinutes()}`
          }:${
            new Date(currentTime).getSeconds() < 10
              ? `0${new Date(currentTime).getSeconds()}`
              : `${new Date(currentTime).getSeconds()}`
          }:${
            Math.round(new Date(currentTime).getMilliseconds() / 38.46) < 10
              ? `0${Math.round(
                  new Date(currentTime).getMilliseconds() / 38.46
                )}`
              : `${Math.round(new Date(currentTime).getMilliseconds() / 38.46)}`
          }`}</div>
        )}
      </div>
    </div>
  );
};
