import { Button, Form, Input, message } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { db } from "../firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Contact = () => {
  const [contactForm] = Form.useForm();
  const [isVis, setIsVis] = useState<null | string>(null);
  const nav = useNavigate();

  const handleContact = () => {
    contactForm.validateFields().then((values) => {
      const newId = nanoid();
      setDoc(doc(db, `contact/${newId}`), {
        id: newId,
        ...values,
        date: new Date().getTime(),
        complete: false,
      })
        .then(() => {
          setIsVis("Thanks, we will get in touch when we can.");
          setTimeout(() => {
            setIsVis(null);
          }, 30000);
          contactForm.resetFields();
        })
        .catch((err) => console.error(err));
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,.8)",
      }}
    >
      <div
        className="verticalCenter"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isVis ? (
          <div>
            <div style={{ color: "whitesmoke", fontFamily: "Oxanium" }}>
              {isVis}
            </div>

            <Button
              type="link"
              style={{ fontFamily: "Oxanium", margin: ".5rem" }}
              onClick={() => {
                nav("/");
              }}
            >
              Home
            </Button>
          </div>
        ) : (
          <div style={{ width: "100%", paddingBottom: "30%" }}>
            <div
              className=" text-white font-semibold mb-6 mt-10 font-bold, text-2xl"
              style={{ fontFamily: "oxanium" }}
            >
              Contact
            </div>
            <Form form={contactForm}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Your name is required" }]}
              >
                <Input style={{ width: "300px" }} placeholder="Your name" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Your contact phone number is required",
                  },
                ]}
              >
                <Input
                  id="phone"
                  style={{ width: "300px" }}
                  placeholder="Your phone number"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Your contact email is required" },
                  {
                    type: "email",
                    message: "This must be a valid email address",
                  },
                ]}
              >
                <Input
                  id="email"
                  style={{ width: "300px" }}
                  placeholder="Your email"
                />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[{ required: true, message: "A message is required" }]}
              >
                <Input.TextArea
                  placeholder="Your message..."
                  style={{ width: "300px" }}
                />
              </Form.Item>
            </Form>

            <Button
              type="link"
              style={{ fontFamily: "Oxanium", margin: ".5rem" }}
              onClick={() => {
                handleContact();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
