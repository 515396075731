import { Button, Form, Input, InputNumber, Select } from "antd";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useAuth } from "../AuthProvider/AuthProvider";
import { db, functions } from "../firebase";
import { Payment } from "./Payment";
import { useRecoilState, useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";

export const Donate = () => {
  // const [userForm] = Form.useForm();
  const [payForm] = Form.useForm();
  const newUserFunc = httpsCallable(functions, "createStripeUser");
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const { width } = useRecoilValue(windowSize);

  const auth = useAuth();

  const [userData, setUserData] = useState<any>(null);

  const [order, setOrder] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<null | string>(null);
  const createPayment = () => {
    if (!auth.currentUser) return;
    const newPaymentId = nanoid();
    setPaymentId(newPaymentId);
    payForm
      .validateFields()
      .then((values) => {
        if (!auth.currentUser) return;
        setDoc(
          doc(
            db,
            `stripe_customers/${auth.currentUser.uid}/payments/${newPaymentId}`
          ),
          { ...values, amount: values.amount * 100 }
        );
      })
      .then(() => setOrder(true))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!auth.currentUser) return;
    const unsub = onSnapshot(
      doc(db, `stripe_customers/${auth.currentUser.uid}`),
      (snapDoc) => {
        setUserData(() => snapDoc.data());
      }
    );
    return unsub;
  }, [auth]);

  useEffect(() => {
    if (!auth.currentUser) return;
    const unsub = onSnapshot(
      doc(db, `stripe_customers/${auth.currentUser.uid}/payments/${paymentId}`),
      (docSnap) => {
        const paymentUpdate = docSnap.data();
        if (paymentUpdate?.client_secret) {
          setClientSecret(paymentUpdate.client_secret);
        }
      },
      (err) => {
        console.error(err);
      }
    );
    return unsub;
  }, [paymentId, auth]);

  return (
    <>
      {auth.currentUser && (
        <div>
          {!clientSecret ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                  maxWidth: "600px",
                  padding: "3rem",
                  backgroundColor: "rgba(200,200,200, .9)",
                  borderRadius: "1rem",
                  fontFamily: "oxanium",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    paddingBottom: "2rem",
                    fontSize: width < 800 ? "3vw" : "1.5rem",
                  }}
                >
                  SUPPORT OUR PROJECTS
                </div>
                <Form form={payForm} initialValues={{ currency: "nzd" }}>
                  <Form.Item name="project">
                    <Select placeholder={"Select a project to support"}>
                      <Select.Option value="nzd">No Where</Select.Option>
                      <Select.Option value="usd">
                        Our Miraculous Climate
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Input
                      placeholder="Your name"
                      about="We will put your name in the credits!"
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: width < 600 ? "column" : "row",
                      justifyContent: "space-between",
                      width: "60%",
                    }}
                  >
                    {width > 599 && (
                      <>
                        <Button
                          onClick={() => payForm.setFieldValue("amount", 5)}
                        >
                          $5
                        </Button>
                        <Button
                          onClick={() => payForm.setFieldValue("amount", 10)}
                        >
                          $10
                        </Button>
                        <Button
                          onClick={() => payForm.setFieldValue("amount", 20)}
                        >
                          $20
                        </Button>
                        <Button
                          onClick={() => payForm.setFieldValue("amount", 50)}
                        >
                          $50
                        </Button>
                        <Button
                          onClick={() => payForm.setFieldValue("amount", 100)}
                        >
                          $100
                        </Button>
                      </>
                    )}
                  </div>
                  <div>Or enter an amount</div>
                  <Form.Item name="amount">
                    <InputNumber placeholder="0" />
                  </Form.Item>
                  <Form.Item name="currency">
                    <Select>
                      <Select.Option value="nzd">NZD</Select.Option>
                      <Select.Option value="usd">USD</Select.Option>
                    </Select>
                  </Form.Item>

                  <Button onClick={() => createPayment()}>Donate</Button>
                </Form>
              </div>
            </div>
          ) : (
            <Payment
              userId={auth.currentUser.uid}
              paymentId={paymentId}
              clientSecret={clientSecret}
            />
          )}
        </div>
      )}
    </>
  );
};
