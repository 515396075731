import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";

export const DocDisplay = () => {
    const { width, height } = useRecoilValue(windowSize);
    const loc = useLocation()
    

 
    return <div
    style={{
      backgroundColor: "white",
      overflowY: "scroll",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
    }}
  >{
    loc &&
 
      <embed
      //@ts-ignore
        src={loc.state.url}
        height={height - 140}
        width={width}
      /> }
  </div>
}