import {
  LoadingOutlined,
  LoginOutlined,
  LogoutOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Button, Image, Spin } from "antd";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { CameraControl } from "../CameraControl/CameraControl";
import { ColorPalette } from "../ColorPalette/ColorPalette";
import { Contact } from "../Contact/Contact";
import { Live } from "../Live/Live";
import { Login } from "../Login/Login";
import { NoAccess } from "../NoAccess/NoAccess";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { Projects } from "../Projects/PublicProjects";
import { AIScriptWriter } from "../ScriptWriter/AIScriptWriter";
import { Services } from "../Services/Services";
import { Teleprompter } from "../Teleprompter/Teleprompter";
import { db } from "../firebase";
import { publicProjectsState, windowSize } from "../recoil/recoil";
import { PublicProjectType } from "../typeDefs";
import bg from "./bg.png";
import { Library } from "../Library/Library";
import { DocDisplay } from "../DocDisplay/DocDisplay";
import { ClapperBoard } from "../ClapperBoard/ClapperBoard";
import { ScenePlan } from "../SceneElement/ScenePlan";
import { Donate } from "../Donate/Donate";
import { PaymentComplete } from "../Donate/PaymentComplete";
import { MemberRoute } from "../MemberRoute/MemberRoute";
import { CustomerRoute } from "../CustomerRoute/CustomerRoute";
import { Customer } from "../Customer/Customer";
import { Calendar } from "../Calendar/Calendar";
import { Invoices } from "../Invoices/Invoices";
import logo from "./../Images/SCRYPTWORX STUDIOS Slim.png";
import { useAuth } from "../AuthProvider/AuthProvider";
import { Tasks } from "../Tasks/Tasks";
import { AudioStream } from "../AudioStream/AudioStream";

const ScriptWriter = React.lazy(() => import("../ScriptWriter/ScriptWriter"));
const Tools = React.lazy(() => import("../Tools/Tools"));
const ProjectAdmin = React.lazy(() => import("../ProjectAdmin/ProjectAdmin"));
const ScriptCreator = React.lazy(
  () => import("../ScriptCreator/ScriptCreator")
);
const WebsiteManagement = React.lazy(
  () => import("../WebsiteManagement/WebsiteManagement")
);

type SocketType = { url: string };

export const Foreground = () => {
  const [publicProjects, setPublicProjects] =
    useRecoilState(publicProjectsState);
  const [socketUrl, setSocketUrl] = useState<null | DocumentData>(null);
  const { width, height } = useRecoilValue(windowSize);
  const spinIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const nav = useNavigate();
  const loc = useLocation();
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "publicProjects"),
      (snap) => {
        setPublicProjects([]);
        snap.docs.forEach((doc) => {
          setPublicProjects((prev) => [
            ...prev,
            doc.data() as PublicProjectType,
          ]);
        });
      },
      (err) => console.error(err)
    );
    return () => unsub();
  }, [setPublicProjects]);

  useEffect(() => {
    getDoc(doc(db, "socket/hwlOdYXgUA1JIimwuugX"))
      .then((snapDoc) => {
        if (snapDoc.data()) {
          setSocketUrl(snapDoc.data() || null);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loc.pathname === "/" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100vh",
            zIndex: "15",
          }}
        >
          <div>
            <Image
              src={logo}
              style={{ width: "15vw", minWidth: "150px" }}
              preview={false}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/services");
                }}
              >
                Services
              </Button>
              <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/projects");
                }}
              >
                Projects
              </Button>
              <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/contact");
                }}
              >
                Contact
              </Button>
            </div>
            <div style={{ paddingTop: "2rem" }}>
              {currentUser ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="text"
                    style={{
                      fontFamily: "Oxanium",
                      color: "white",
                    }}
                    onClick={() => {
                      nav("/tools");
                    }}
                    icon={<ToolOutlined />}
                  />
                  <Button
                    type="text"
                    style={{ fontFamily: "oxanium", color: "white" }}
                    onClick={() => {
                      logout();
                    }}
                    icon={<LogoutOutlined />}
                  />
                </div>
              ) : (
                <Button
                  type="text"
                  style={{ fontFamily: "oxanium", color: "white" }}
                  onClick={() => nav("/login")}
                  icon={<LoginOutlined />}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100vw",
              position: "fixed",
              zIndex: "15",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Routes>
              <Route
                path="/services"
                element={
                  <Suspense fallback={<Spin indicator={spinIcon} />}>
                    <Services />
                  </Suspense>
                }
              />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/livestream/:name/:key"
                element={
                  <Suspense fallback={<Spin indicator={spinIcon} />}>
                    <Live />
                  </Suspense>
                }
              />
              <Route path="/unauthorised-access" element={<NoAccess />} />
              <Route
                path="/tools"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Tools />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/tasks"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Tasks />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/audio-stream"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      {socketUrl ? (
                        <AudioStream socketUrl={socketUrl.url} />
                      ) : (
                        <div>No Socket</div>
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/colour-palette"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <ColorPalette />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/calendar"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Calendar />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/clapperboard"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <ClapperBoard />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/script-creator"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <ScriptCreator />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/project-admin/:projectid"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <ProjectAdmin />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/script-writer/:scriptid"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <AIScriptWriter />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/scene-planning/:sceneid"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <ScenePlan />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/website-management"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <WebsiteManagement />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/donate"
                element={
                  <MemberRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Donate />
                    </Suspense>
                  </MemberRoute>
                }
              />
              <Route
                path="/camera-control"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <CameraControl />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/invoices"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Invoices />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/library"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Library />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/library/doc"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <DocDisplay />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/teleprompter"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Teleprompter />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/thanks"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <PaymentComplete />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/order"
                element={
                  <CustomerRoute>
                    <Suspense fallback={<Spin indicator={spinIcon} />}>
                      <Customer />
                    </Suspense>
                  </CustomerRoute>
                }
              />
            </Routes>
          </div>
        </div>
      )}
      {/* <Footer height={height} /> */}
    </div>
  );
};
