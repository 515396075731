import { Button } from "antd";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { db } from "../firebase";
import { windowSize } from "../recoil/recoil";
import { ServiceType } from "../typeDefs";
import * as THREE from "three";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/Addons";
import objM from "./../services2.glb";

export const Services = () => {
  const { width } = useRecoilValue(windowSize);
  const [services, setServices] = useState<Array<ServiceType>>([]);
  const [serviceIndex, setServiceIndex] = useState<Array<number> | null>(null);
  const [mousePos, setMousePos] = useState(0);
  const [txtSz, setTxtSz] = useState(1);

  useEffect(() => {
    const q = query(
      collection(db, "/services"),
      where("isDeleted", "==", false)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setServices([]);
      snapshot.forEach((doc) => {
        setServices((prev) => [...prev, doc.data() as ServiceType]);
      });
      setServiceIndex(() => [snapshot.size - 2, 0, 1]);
    });
    return unsub;
  }, []);

  const objRef = useRef<THREE.Group>();

  const url =
    "https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/images%2Fcube.glb?alt=media&token=a617a372-06c8-4cc1-83b3-798de22103a3";
  const { scene } = useLoader(GLTFLoader, objM);
  // const { scene } = useGLTF(
  //   "https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/images%2Fcube.glb?alt=media&token=a617a372-06c8-4cc1-83b3-798de22103a3"
  // );
  // (loader: THREE.Loader) => {
  //   loader.setCrossOrigin("anonymous");
  // }
  useEffect(() => {
    if (objRef.current) {
      objRef.current.add(scene);
    }
  }, [scene]);

  useEffect(() => {
    let direction = true;
    const list = (e: MouseEvent) => {
      setMousePos(e.x);
      if (e.movementX > 0) {
        direction = true;
      } else {
        direction = false;
      }

      if (objRef.current && e.x > width / 3 && e.x < (width / 3) * 2) {
        objRef.current?.rotateY(e.movementX / 100);
      }
    };

    window.addEventListener("mousemove", list);

    let interval: NodeJS.Timer | null = null;
    interval = setInterval(() => {
      console.log("r");
      if (objRef.current && direction) {
        objRef.current?.rotateY(0.02);
      } else if (objRef.current && !direction) {
        objRef.current?.rotateY(-0.02);
      }
    }, 50);

    return () => {
      if (!interval) return;
      clearInterval(interval);
      window.removeEventListener("mousemove", list);
    };
  }, [width]);

  const add = () => {
    if (!serviceIndex) return;
    let idx = serviceIndex[1];
    if (idx > 1 && idx <= services.length - 1) {
      setServiceIndex([idx - 2, idx - 1, idx]);
    } else if (idx === 0) {
      setServiceIndex([services.length - 2, services.length - 1, 0]);
    } else if (idx === 1) {
      setServiceIndex([services.length - 1, 0, 1]);
    }
  };

  const subtract = () => {
    if (!serviceIndex) return;
    let idx = serviceIndex[1];
    if (idx < services.length - 2 && idx >= 0) {
      setServiceIndex(() => [idx, idx + 1, idx + 2]);
    } else if (idx === services.length - 2) {
      setServiceIndex([services.length - 2, services.length - 1, 0]);
    } else if (idx === services.length - 1) {
      setServiceIndex([services.length - 1, 0, 1]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <div style={{ width: "100vw", height: "100vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100vh",
            }}
          >
            {services.length > 0 && serviceIndex !== null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundImage: `url(${services[serviceIndex[1]].image})`,
                }}
              >
                {/* {services[serviceIndex[1]].image &&
                  canvasEl.current !== undefined && (
                    <img
                      src={services[serviceIndex[1]].image}
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        padding: "1rem",
                        borderRadius: "1rem",
                      }}
                      alt="Service"
                      // preview={false}
                    />
                  
                  )} */}
                {/* <div ref={divEl}></div> */}
                <Canvas
                  style={{ width: "100%", height: "30vh" }}
                  camera={{
                    fov: 60,
                    near: 0.1,
                    far: 1000,
                    position: [0, 0, 3.1],
                    focus: 2,
                    zoom: 1,
                  }}
                >
                  <ambientLight intensity={0.2} />
                  <directionalLight
                    position={[0, 10, 5]}
                    intensity={4}
                    rotation={[1, 1, 10]}
                  />
                  <primitive object={scene} ref={objRef} />
                </Canvas>
                <div
                  style={{
                    display: "flex",
                    flexDirection: width > 800 ? "row" : "column",
                    alignItems: "center",
                    paddingBottom: "1rem",
                  }}
                >
                  <Button
                    type="link"
                    style={{
                      fontFamily: "Oxanium",
                      color: "white",
                      marginRight: width > 800 ? "1rem" : "0rem",
                    }}
                    onClick={() => add()}
                  >
                    {services[serviceIndex[0]].title}
                  </Button>
                  <div
                    style={{
                      color: "dodgerblue",
                      fontFamily: "Oxanium",
                      fontSize: width > 800 ? "5vw" : "2rem",
                      userSelect: "none",
                    }}
                  >
                    {services[serviceIndex[1]].title}
                  </div>
                  <Button
                    type="link"
                    style={{
                      fontFamily: "Oxanium",
                      color: "white",
                      marginLeft: width > 800 ? "1rem" : "0rem",
                    }}
                    onClick={() => subtract()}
                  >
                    {services[serviceIndex[2]].title}
                  </Button>
                </div>
                <div
                  style={{
                    color: "whitesmoke",
                    fontFamily: "Oxanium",
                    fontSize: "1rem",
                    paddingBottom: "1rem",
                    paddingTop: "2rem",
                    borderTop: "1px solid rgb(20,20,20)",
                    width: "90%",
                    maxWidth: "400px",
                    userSelect: "none",
                  }}
                >
                  {services[serviceIndex[1]].subtitle}
                </div>
                <div
                  style={{
                    color: "whitesmoke",
                    fontFamily: "Oxanium",
                    fontSize: `${txtSz}rem`,
                    width: "90%",
                    maxWidth: "500px",
                    textAlign: "justify",
                    userSelect: "none",
                  }}
                  // onMouseEnter={() => {
                  //   setTxtSz(1.1);
                  // }}
                  // onMouseLeave={() => setTxtSz(0.9)}
                >
                  {services[serviceIndex[1]].content}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
