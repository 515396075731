import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider/AuthProvider";
import { Button, Input } from "antd";


export const Login = () => {
  const [value, setValue] = useState<string>("");
  const [value2, setValue2] = useState<string>("");
  const nav = useNavigate();
  const { login } = useAuth();

  const handleForm = () => {
    login(value, value2);
    nav("/tools");
  };

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          //width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div>
          <div
            className=" text-white font-semibold mb-6 mt-10 font-bold, text-2xl"
            style={{ fontFamily: "oxanium" }}
          >
            LOGIN
          </div>
          <div className="m-1">
            <Input
              id="userName"
              style={{ width: "250px" }}
              value={value}
              placeholder="User name"
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>
          <div className="m-2">
            <Input.Password
              id="password"
              style={{ width: "250px" }}
              value={value2}
              placeholder="Password"
              onChange={(e) => {
                setValue2(e.target.value);
              }}
            />
          </div>
          <div>
            <Button type="primary" onClick={() => handleForm()}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
