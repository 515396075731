import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { getBlob, ref } from "firebase/storage";
import { Document, Page } from "react-pdf";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
import { collection, onSnapshot } from "firebase/firestore";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const Library = () => {
  const [docs, setDocs] = useState<Array<any>>([]);
  const nav = useNavigate();
  const { height } = useRecoilValue(windowSize);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "docs"), (snap) => {
      setDocs([]);
      snap.forEach((snapDoc) => {
        setDocs((prev) => [...prev, snapDoc.data()]);
      });
    });
    return unsub;
  }, []);

  return (
    <div
      style={{
        overflowY: "scroll",
        height: `100vh`,
        width: "100vw",
      }}
    >
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <div>SWS Library</div>
        {docs.map((singleDoc, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "90%",
                maxWidth: "400px",
                padding: ".25rem",
                alignSelf: "center",
              }}
            >
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => nav("doc", { state: { url: singleDoc.url } })}
              >
                {singleDoc.title}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
